import React, { FC } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
// style
import * as styles from '@/newcar/ui/top/organism/ColumnCarousel.module.styl';

const Carousel: FC = () => {
  const data = useStaticQuery<GatsbyTypes.allNewcarColumnsQuery>(
    graphql`
      query allNewcarColumns {
        allMicrocmsNewcarColumn 
        (
          sort: {fields: [topDisplayNum], order: ASC},
          filter:{topDisplayNum: {ne: null}}
          limit: 5
        )
        {
          edges {
            node {
              slug
              title
              titleImage {
                image {
                  url
                }
              }
            }
          }
        }
      }
    `,
  );

  /* 5件のコラムデータ格納  */
  const columnArray = data.allMicrocmsNewcarColumn.edges;

  return (
    <>
      {columnArray.map((contents) => (
        <div className="column is-full is-border-bottom-line is-padding-3">
          <Link
            to={`/column/newcar/${contents.node.slug}/`}
            className={`${styles.columnLink} top-news-contents is-link-redbrown is-size-5`}
          >
            <div className={styles.columnThumbnailContainer}>
              <img
                src={contents.node.titleImage?.image?.url}
                alt={contents.node.title}
                className={styles.columnThumbnailImage}
                width="95"
                height="95"
              />
            </div>
            <span className={styles.columnTitle}>
              {contents.node.title}
            </span>
          </Link>
        </div>
      ))}
    </>
  );
};

export default Carousel;
