import React, { FC, ComponentProps } from 'react';

import afterSupportFirst from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-first.png';
import afterSupportFirstAvif from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-first.avif';
import afterSupportSecond from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-second.png';
import afterSupportSecondAvif from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-second.avif';
import afterSupportThird from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-third.png';
import afterSupportThirdAvif from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-third.avif';
import afterSupportFourth from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-fourth.png';
import afterSupportFourthAvif from '@/newcar/ui/top/img/aftersupport/after-support-thumbnail-fourth.avif';

import * as styles from '@/newcar/ui/top/template/index.module.styl';

const AfterSupport: FC<{} & ComponentProps<'section'>> = ({ className }) => (
  <section className={className}>
    <div className={`has-background-white ${styles.afterSupportInner}`}>
      <div className={`has-background-light-yellow ${styles.afterSupportContainer}`}>
        {/* ここは全体調整後に出現する（今は上のセクションに入っている模様） */}
        <h2
          className={`${styles.big} ${styles.commonTitle} ${styles.contentsTitle} ${styles.isAfterSupport}`}
        >
          <span className={styles.commonTitleMinText}>定額カルモくんは</span>
          <br className="is-hidden-tablet" />
          ご契約後サポート・特典が充実
        </h2>
        {/* // ここは全体調整後に出現する（今は上のセクションに入っている模様） */}
        <ul className={`container ${styles.afterSupportList}`}>
          <li className={styles.afterSupportListItem}>
            <h3 className={styles.afterSupportListTitle}>
              車検時期の
              <br />
              お知らせ
            </h3>
            <picture>
              <source srcSet={afterSupportFirstAvif} type="image/avif" />
              <img
                className={styles.afterSupportThumbnail}
                src={afterSupportFirst}
                alt="車検時期のお知らせ"
                width="195"
                height="96"
              />
            </picture>
            <p className={styles.afterSupportDescription}>
              忘れがちな車検は、時期が近づいたらお知らせがあるので安心。車検のやり方についても丁寧にサポートします。
            </p>
          </li>
          <li className={styles.afterSupportListItem}>
            <h3 className={styles.afterSupportListTitle}>
              お支払い状況は
              <br />
              アプリで簡単確認
            </h3>
            <picture>
              <source srcSet={afterSupportSecondAvif} type="image/avif" />
              <img
                className={styles.afterSupportThumbnail}
                src={afterSupportSecond}
                alt="お支払い状況はアプリで簡単確認"
                width="195"
                height="96"
              />
            </picture>
            <p className={styles.afterSupportDescription}>
              お支払い状況、加入プランの詳細、ご契約者様限定特典の情報などは、アプリからいつでもご確認いただけます。
            </p>
          </li>
          <li className={styles.afterSupportListItem}>
            <h3 className={styles.afterSupportListTitle}>
              ご不明点の
              <br />
              スピード解決！
            </h3>
            <picture>
              <source srcSet={afterSupportThirdAvif} type="image/avif" />
              <img
                className={styles.afterSupportThumbnail}
                src={afterSupportThird}
                alt="ご不明点のスピード解決！"
                width="195"
                height="96"
              />
            </picture>
            <p className={styles.afterSupportDescription}>
              わからないことはお客様サポートの専門チームに電話・メール・LINEでご相談可能。メール・LINEは24時間いつでもご相談をお送りいただけます。
            </p>
          </li>
          <li className={styles.afterSupportListItem}>
            <h3 className={styles.afterSupportListTitle}>
              ご契約者様
              <br />
              限定特典
            </h3>
            <picture>
              <source srcSet={afterSupportFourthAvif} type="image/avif" />
              <img
                className={styles.afterSupportThumbnail}
                src={afterSupportFourth}
                alt="ご契約者様限定特典"
                width="195"
                height="96"
              />
            </picture>
            <p className={styles.afterSupportDescription}>
              レンタカー、レジャー予約、サブスクなど、提携サイトでお得なクーポン配布やポイントバックがあります。
            </p>
            <span className={styles.afterSupportListAttention}>※内容は一例です。変更になる可能性がございます。</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default AfterSupport;
